import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function Willow({ styles, editing, ...props }) {
  const Elements = useComponents()
  let video_bg_style = styles.forElement("video_background")

  if (editing) {
    video_bg_style["position"] = "absolute"
  }

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-wrapper">
        <div
          className={`formkit-video-container ${
            editing ? "formkit-inherit" : "formkit-fixed"
          }`}
        >
          <Elements.Video
            id="video"
            name="video"
            className="formkit-video"
            defaults={{
              src: "https://youtu.be/CCbMaRt15qY"
            }}
          />
          <div
            style={{
              backgroundColor: styles.forElement("background").backgroundColor
            }}
            className="formkit-bg-color"
          />
          <div
            style={styles.forElement("background")}
            className="formkit-video-background"
          />
        </div>
        <div
          className="formkit-main-container"
          style={styles.forElement("form_background")}
        >
          <div className="formkit-content-container">
            <Elements.Content
              className="formkit-preheading"
              name="preheading"
              defaults={{
                content: "<p>New Music Video</p>"
              }}
            />
            <Elements.Heading
              className="formkit-heading"
              name="heading"
              defaults={{
                content: "The rhythm of the story"
              }}
            />
            <Elements.Region className="formkit-content" name="content">
              <Elements.Content
                defaults={{
                  content:
                    "<p>Alecia's latest single is here with a brand new music video. Sign up below to get the hit single for free, as well as exclusive Alecia updates!</p>"
                }}
              />
            </Elements.Region>
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields>
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Download the single" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              name="disclaimer"
              defaults={{
                content:
                  "<p>We respect your privacy. Unsubscribe at any time.</p>"
              }}
            />
            <Elements.BuiltWith background="form_background" />
          </div>
        </div>
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(Willow, { name: "Willow" })
